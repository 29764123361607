



























































































































import Datatable from "@/utils/datatable/datatable";
import { Header } from "@/utils/datatable/datatableInterface";
import { currency , parseDate} from "@/utils/helpers";
import AppForm from '@/utils/form/components/Form.vue'

import Vue from "vue";
import { InputInterface } from "@/utils/form/interface";
export default Vue.extend({
  props: {
    table: Datatable,
  },
  data() {
    return {
      dialog : false
    }
  },
  components:{
    AppForm
  },
  computed:{
    totalsHeaders(){
      return this.table.headers.filter((header:Header) => {
        return header.isTotal ? header : ''
      })
    }
  },
  methods: {
    parseDate,
    currency: (x: number) => currency(x),
    filter(val:any){
      // reset headers totals to avoid sum bug
      // if we dont do this the class will add the totals to thee preevios data totals
      this.table.headers.forEach((header:Header) => {
        header.total = 0
      })
        this.table.getData()
    },
    showTotals(){
      this.dialog=true
    }
  },
});
